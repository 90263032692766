import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { PageHeader, PageControls } from './shared';
import { VehicleBrandSelect } from '../Controls';
import { useForm, usePage, useEnvironment } from '../../context/ContextProvider';
import vinValidator from '../../validators/vin-number';

function VehicleDetails() {
  const { t } = useTranslation();
  const { nextPage } = usePage();
  const { formData, handleInputChange } = useForm();
  const { environment } = useEnvironment();
  const { styling } = environment;
  const { chassisnumberField } = styling;

  const chassisNumberRequired = chassisnumberField === 'required';
  const chassisNumberHidden = chassisnumberField === 'hidden';

  const onSubmit = async (event) => {
    event.preventDefault();
    const vin = formData?.vehicle.chassis_number;
    if (!chassisNumberHidden && (vin?.length > 0 && !vinValidator(vin))) {
      toast.error(t('vehicleDetails.invalidVin'));
    } else {
      nextPage();
    }
  };

  return (
    <>
      <PageHeader title={t('vehicleDetails.title')} subtitle={t('vehicleDetails.subtitle')} />
      <Form onSubmit={onSubmit}>
        <Row className="text-center">
          <Col>
            <Form.Label>{t('vehicleDetails.brand')}</Form.Label>
            <VehicleBrandSelect
              value={formData?.vehicle.brand}
              name="vehicle.brand"
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row className="text-center mt-2">
          <Col>
            <Form.Label>{t('vehicleDetails.description')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="vehicle.description"
              className="text-center"
              type="text"
              placeholder={t('vehicleDescriptionPlaceholder')}
              value={formData?.vehicle.description}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        {!chassisNumberHidden && (
          <Row className="text-center mt-2">
            <Col>
              <Form.Label>{t('vehicleDetails.chassisNumber')}</Form.Label>
              <Form.Control
                required={chassisNumberRequired}
                autoComplete="off"
                name="vehicle.chassis_number"
                className="text-center"
                type="text"
                minLength={17}
                maxLength={17}
                placeholder={t('vehicleDetails.chassisNumber')}
                value={formData?.vehicle.chassis_number}
                onChange={(event) => {
                  // convert to uppercase
                  const value = event.target.value.toUpperCase();
                  // remove non-alphanumeric characters
                  const numericValue = value.replace(/[^A-Z0-9]/g, '');
                  // set the value
                  event.target.value = numericValue;
                  handleInputChange(event);
                }}
              />
            </Col>
          </Row>
        )}
        <PageControls />
      </Form>
    </>
  );
}

export default VehicleDetails;