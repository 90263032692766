import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons';
import { useEnvironment, usePage } from '../../context/ContextProvider';
function Error({ message }) {
  const { t } = useTranslation();
  const { environment } = useEnvironment();
  const { setPageError, goToPage } = usePage();
  return (
    <div className="text-center">
      <b className="d-block">
        <FontAwesomeIcon icon={faTimes} className="text-danger" /> &nbsp;
        {t(message)}
      </b>
      <div>
        <FontAwesomeIcon icon={faEnvelope} className="text-primary" />
        &nbsp;
        <a className="link-primary" href={`mailto:${environment?.communication.shopEmail}`}>
          {environment?.communication.shopEmail}
        </a>
        <br />
        <FontAwesomeIcon icon={faPhone} className="text-primary" />
        &nbsp;
        <a className="link-primary" href={`tel:${environment?.communication.shopPhone}`}>
          {environment?.communication.shopPhone}
        </a>
      </div>
      <hr />
      <Button variant="secondary" onClick={() => {setPageError(null); goToPage(0);}}>
        <FontAwesomeIcon icon={faHome} />
        &nbsp;
        {t('error.goToStart')}
      </Button>
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Error;
