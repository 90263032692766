import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageControls } from './shared';
import { useForm, usePage } from '../../context/ContextProvider';
import { getTyres } from '../../handlers/webstreamApiCalls';

function TyreDetails({ getQuantities }) {
  const { formData, setFormData, handleInputChange } = useForm();
  const { nextPage } = usePage();
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      const tyres = await getTyres();
      if (tyres.length) {
        const tyreDescriptions = tyres.map((tyre) => tyre.description);
        const frontTyres = tyreDescriptions[0];
        let backTyresDifferent = false;
        let backTyres;

        let frontQuantities = 4;  // Default to 4 for front-only
        let backQuantities;

        // front tyres have different descriptions than back tyres
        if (tyreDescriptions[0] !== tyreDescriptions[2]) {
          backTyresDifferent = true;
          backTyres = tyreDescriptions[2];
          frontQuantities = 2;    // Change to 2 when both front and back
          backQuantities = 2;
        }
        setFormData({
          ...formData,
          tyreInput: {
            frontTyres,
            backTyresDifferent,
            backTyres,
            ...getQuantities ? {
              frontQuantities,
              backQuantities,
            } : {},
          }
        });
      }
    })();
  }, []);

  const handleSubmit = (event) => {
    if (formData.tyreInput.backTyresDifferent
      && (formData.tyreInput.frontTyres === formData.tyreInput.backTyres)) {
      // in case of same tyres, set back tyres to undefined
      setFormData({
        ...formData,
        tyreInput: {
          ...formData.tyreInput,
          backTyresDifferent: false,
          backTyres: undefined
        }
      });
    }
    event.preventDefault();
    nextPage();
  };

  return (
    <>
      <PageHeader title={t('tyreDetails.title')} subtitle={t('tyreDetails.subtitle')} />
      <Form onSubmit={handleSubmit}>
        <Row className="text-center mt-2 g-1">
          <Col xs={12} md={getQuantities ? 9 : 12}>
            <Form.Label>{t('tyreDetails.tyres')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="tyreInput.frontTyres"
              className="text-center"
              type="text"
              placeholder={t('tyreDetails.tyres')}
              value={formData?.tyreInput?.frontTyres}
              onChange={handleInputChange}
            />
          </Col>
          {getQuantities && (
            <Col xs={12} md={3}>
              <Form.Label>{t('tyreDetails.quantities')}</Form.Label>
              <Form.Control
                required
                autoComplete="off"
                name="tyreInput.frontQuantities"
                className="text-center"
                type="number"
                min={1}
                step={1}
                placeholder={t('tyreDetails.quantities')}
                value={formData?.tyreInput?.frontQuantities}
                onChange={handleInputChange}
              />
            </Col>
          )}
        </Row>
        {formData?.tyreInput?.backTyresDifferent && (
          <Row className="text-center mt-2 g-1">
            <Col xs={10} md={getQuantities ? 9 : 12}>
              <Form.Label>{t('tyreDetails.backTyres')}</Form.Label>
              <Form.Control
                required
                autoComplete="off"
                name="tyreInput.backTyres"
                className="text-center"
                type="text"
                placeholder={t('tyreDetails.backTyres')}
                value={formData?.tyreInput?.backTyres}
                onChange={handleInputChange}
              />
            </Col>
            {getQuantities && (
              <Col xs={12} md={3}>
                <Form.Label>{t('tyreDetails.quantities')}</Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  name="tyreInput.backQuantities"
                  className="text-center"
                  type="number"
                  min={1}
                  step={1}
                  placeholder={t('tyreDetails.quantities')}
                  value={formData?.tyreInput?.backQuantities}
                  onChange={handleInputChange}
                />
              </Col>
            )}
          </Row>
        )}
        <Form.Group className="d-flex justify-content-center mt-2">
          <Form.Check
            type="checkbox"
            id="backTyresDifferent"
            name="tyreInput.backTyresDifferent"
            checked={formData?.tyreInput?.backTyresDifferent}
            onChange={handleInputChange}
            label={t('tyreDetails.backTyresDifferent')}
          />
        </Form.Group>
        <PageControls />
      </Form>
    </>
  );
}

TyreDetails.propTypes = {
  getQuantities: PropTypes.bool,
};

export default TyreDetails;