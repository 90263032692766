import React, { useEffect, useState, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PageHeader, PageControls } from './shared';
import { useForm, usePage, useEnvironment } from '../../context/ContextProvider';
import { getSchedule } from '../../handlers/calendar';
import groupSlotsByDuration from '../../handlers/groupSlotsByDuration';
import 'react-datepicker/dist/react-datepicker.css';

const MAX_SLOTS_TO_SHOW = 10;
const INITIAL_FETCH_RANGE = 30;
const FETCH_RANGE_BEFORE_AFTER = 3;
function DatePicker() {
  const { formData, setFormData } = useForm();
  const { nextPage } = usePage();
  const { environment } = useEnvironment();
  const { openingDays, closingDates } = environment?.schedule ?? {};
  const [pickedDate, setPickedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [earliestBookingDate, setEarliestBookingDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const isFirstRender = useRef(true);

  const fetchedDates = formData.fetchedDates ?? [];
  const availableDates = formData.availableDates ?? [];

  useEffect(() => {
    if (isFirstRender.current && !formData.agendaSlots?.length) {
      setLoading(true);
      // fetch 30 days (which is the maximum range for the initial fetch)
      getSchedule(formData.selectedService._id, dayjs(), dayjs().add(INITIAL_FETCH_RANGE, 'day')).then(({ availableSlots, earliestBookingDate }) => {
        const groupedSlots = groupSlotsByDuration(availableSlots, formData.selectedService.duration);
        // set 30 days as available dates
        const _fetchedDates = Array.from({ length: INITIAL_FETCH_RANGE }, (_, i) => dayjs().add(i, 'day').format('YYYYMMDD'));
        const _availableDates = Array.from(new Set(groupedSlots.map((slot) => slot.date)));
        setFormData({
          ...formData,
          fetchedDates: _fetchedDates,
          availableDates: _availableDates,
          agendaSlots: groupedSlots,
        });
        setEarliestBookingDate(earliestBookingDate);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        setLoading(false);
        isFirstRender.current = false;
      });
    }
  }, []);

  // merge new slots with existing slots, do not keep duplicates
  const mergeSlots = (newSlots) => {
    const existingSlots = formData.agendaSlots;
    const existingSlotKeys = new Set(existingSlots.map((slot) => slot.key));
    const filteredNewSlots = [];
    newSlots.forEach((slot) => {
      if (!existingSlotKeys.has(slot.key)) {
        filteredNewSlots.push(slot);
      }
    });
    const mergedSlots = [...existingSlots, ...filteredNewSlots].sort((a, b) => a.key.localeCompare(b.key));
    return mergedSlots;
  };

  const fetchMoreSlots = async (chosenDate) => {
    const { selectedService } = formData;
    try {
      setLoading(true);
      // get slots n-days before and after chosen date
      const startDate = dayjs(chosenDate).subtract(FETCH_RANGE_BEFORE_AFTER, 'day');
      const endDate = dayjs(chosenDate).add(FETCH_RANGE_BEFORE_AFTER, 'day');
      const { availableSlots } = await getSchedule(selectedService._id, startDate, endDate);
      setLoading(false);
      const groupedSlots = groupSlotsByDuration(availableSlots, selectedService.duration);
      const newFetchedDates = [];
      const newAvailableDates = Array.from(new Set(groupedSlots.map((slot) => slot.date)));
      // create array of dates of today -n until today +n
      for (let i = -1 * FETCH_RANGE_BEFORE_AFTER; i < (FETCH_RANGE_BEFORE_AFTER + 1); i++) {
        newFetchedDates.push(dayjs(chosenDate).add(i, 'day').format('YYYYMMDD'));
      }
      const mergedSlots = mergeSlots(groupedSlots);
      setFormData({
        ...formData,
        fetchedDates: Array.from(new Set([...formData.fetchedDates, ...newFetchedDates])),
        availableDates: Array.from(new Set([...formData.availableDates, ...newAvailableDates])),
        agendaSlots: mergedSlots
      });
    } catch (error) {
      console.error(error);
    }
  };

  const agendaSlotButton = (slot, index, withDate) => {
    const { date, start, column } = slot;
    const formattedDate = dayjs(date, 'YYYYMMDD').format('DD/MM/YYYY');
    const formattedTime = `${start.slice(0, 2)}:${start.slice(2, 4)}`;
    const label = withDate ? `${formattedDate} - ${formattedTime}` : formattedTime;
    const selectedSlot = formData.selectedSlot;
    const slotIsSelected = selectedSlot?.start === start
      && selectedSlot?.column === column
      && selectedSlot.date === date;

    const handleSlotSelect = () => {
      setFormData({ ...formData, selectedSlot: slot });
      nextPage();
    };

    return (
      <Col xs={withDate ? 12 : 4} md={withDate ? 6 : 2} key={`datepicker-button-${index}`}>
        <Button
          className="datepicker-button"
          onClick={handleSlotSelect}
          variant={slotIsSelected ? 'primary' : 'outline-primary'}
        >
          <p className="mb-0"><b>{label}</b></p>
        </Button>
      </Col>
    );
  };

  const mostRecentSlots = formData.agendaSlots
    ?.slice(0, MAX_SLOTS_TO_SHOW)
    .map((slot, index) => agendaSlotButton(slot, index, true));

  const pickedDateSlots = pickedDate
    ? formData.agendaSlots
      ?.filter((slot) => slot.date === dayjs(pickedDate).format('YYYYMMDD'))
      .map((slot, index) => agendaSlotButton(slot, index, false))
    : null;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.selectedSlot) {
      toast.error(t('datePicker.selectSlotFirst'));
      return;
    }
    nextPage();
  };

  const handleDateSelect = async (date) => {
    if (!fetchedDates.includes(dayjs(date).format('YYYYMMDD'))) {
      // fetch additional slots if date is after earliest booking date
      await fetchMoreSlots(date);
    }
    setPickedDate(date);
  };

  const filterDate = (date) => {
    const formattedDate = dayjs(date).format('YYYYMMDD');
    // Return true only if the date is not in fetchedDates, regardless of availability
    if (availableDates.includes(formattedDate)) {
      return true;
    };
    if (fetchedDates.includes(formattedDate)) {
      return false;
    }
    if (openingDays?.length && !openingDays.includes(date.getDay())) {
      return false;
    }
    if (closingDates && closingDates.map((closingDate) => dayjs(closingDate).format('YYYYMMDD')).includes(formattedDate)) {
      return false;
    }
    return true;
  };

  const getDayClassName = (date) => {
    const dayjsDate = dayjs(date);
    if (dayjsDate.isBefore(dayjs(), 'day')) {
      return 'past-date';
    }
    const formattedDate = dayjsDate.format('YYYYMMDD');
    if (availableDates.includes(formattedDate)) {
      return 'available-date';
    }
    if (fetchedDates.includes(formattedDate)) {
      return 'unavailable-date';
    }
    if (openingDays?.length && !openingDays.includes(date.getDay())) {
      return 'unavailable-date';
    }
    if (closingDates && closingDates.map((closingDate) => dayjs(closingDate).format('YYYYMMDD')).includes(formattedDate)) {
      return 'unavailable-date';
    }
    return 'unchecked-date';
  };

  const pickedDateSlotsSegment = (
    <>
      <Col sm={12}>{t('datePicker.displayingSlotsForDate')} {dayjs(pickedDate).format('DD/MM/YYYY')}</Col>
      {pickedDateSlots?.length ? pickedDateSlots : (<Col>{t('datePicker.noSlotsOnThisDate')}</Col>)}
    </>
  );

  return (
    <>
      <PageHeader title={t('datePicker.title')} subtitle={t('datePicker.subtitle')} />
      <Form onSubmit={handleSubmit}>
        {showCalendar && (
          <ReactDatePicker
            inline
            selected={pickedDate ?? undefined}
            minDate={new Date(earliestBookingDate)}
            filterDate={filterDate}
            dayClassName={getDayClassName}
            onChange={handleDateSelect}
          />
        )}
        <Row className="agenda-slot-container text-center g-2 mt-2 justify-content-center">
          { loading && <div className="text-center"><FontAwesomeIcon icon={faSpinner} spin /> {t('datePicker.loadingAgenda')}</div> }
          {!showCalendar && (<p>{t('datePicker.showingMostRecentSlots')}</p>)}

          { !loading && showCalendar && pickedDateSlots && pickedDateSlotsSegment}
          { !loading && !showCalendar && mostRecentSlots }
        </Row>
        <hr />
        <Row className="text-center g-2">
          {showCalendar && (
            <Col sm={12}>
              <Button
                onClick={() => {
                  setPickedDate(null);
                  setShowCalendar(false);
                }}
                disabled={loading}
                className="datepicker-button">
                <FontAwesomeIcon icon={faClock} />&nbsp;
                {t('datePicker.showMostRecent')}
              </Button>
            </Col>
          )}
          {!showCalendar && (
            <Col sm={12}>
              <Button
                disabled={loading}
                onClick={() => {
                  setShowCalendar(true);
                  setPickedDate(null);
                }}
                className="datepicker-button">
                <FontAwesomeIcon icon={faCalendar} />&nbsp;
                {t('datePicker.showCalendar')}
              </Button>
            </Col>
          )}
        </Row>
        <PageControls loading={loading} />
      </Form>
    </>
  );
}

export default DatePicker;
