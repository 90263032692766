import React from 'react';
import PropTypes from 'prop-types';
import VehicleBrands from '../../constants/vehicle-brands';
import { useTranslation } from 'react-i18next';
import Creatable from 'react-select/creatable';

const brandOptions = VehicleBrands.map(option => {
  return {
    value: option,
    label: option,
  };
});

function VehicleBrandSelect({ value, onChange, name }) {
  const { t } = useTranslation();
  const selectOptions = [
    ...brandOptions,
    { value: t('unknownBrand'), label: t('unknownBrand') },
  ];
  let defaultSelected;
  if (value) {
    defaultSelected = selectOptions.find(option => option.value.toUpperCase() === value.toUpperCase())
      || { value: value, label: value };
  }
  return (
    <Creatable
      className="react-select-container"
      classNamePrefix="react-select-clearable"
      required
      options={selectOptions}
      onChange={(selected) => onChange({
        target: {
          value: selected?.value ?? undefined,
          name,
        },
      })}
      onCreateOption={(inputValue) => {
        onChange({
          target: {
            value: inputValue,
            name,
          },
        });
      }}
      maxMenuHeight={125}
      isSearchable
      value={defaultSelected}
      placeholder={t('vehicleBrandSelect.placeholder')}
      noOptionsMessage={() => t('vehicleBrandSelect.emptyLabel')}
      isClearable
      // show create option if input value is not in options
      isValidNewOption={(inputValue, selectValue, selectOptions) => {
        const trimmedInput = inputValue?.trim().toLowerCase();
        const matchingOptions = selectOptions.filter(option =>
          option.label.toLowerCase().includes(trimmedInput)
        );
        return trimmedInput && matchingOptions.length === 0;
      }}
      // format create option label
      formatCreateLabel={(inputValue) => `${t('useBrand')} "${inputValue}"`}
    />
  );
}

VehicleBrandSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default VehicleBrandSelect;