import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function NextButton({ onClick, className, icon, loading, text }) {
  const { t } = useTranslation();
  return (
    <Button type="submit" disabled={loading} onClick={onClick} className={`nav-button ${className}`} variant="primary">
      {icon && (
        <>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : icon}
          &nbsp;
        </>
      )}
      {text ?? t('next')}
    </Button>
  );
}

NextButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.element,
  loading: PropTypes.bool,
  text: PropTypes.string,
};

export default NextButton;