import React, { useState  } from 'react';
import toast from 'react-hot-toast';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageControls } from './shared';
import { useForm, useEnvironment, usePage } from '../../context/ContextProvider';
import { CountryCodeSelect } from '../Controls';
import { validatePhoneNumber } from '../../handlers/mobileValidation';

function DriverDetails() {
  const { t, i18n  } = useTranslation();
  const { nextPage } = usePage();
  const [loading, setLoading] = useState(false);
  const { language } = i18n ?? {};
  const {
    formData,
    setFormData,
    handleInputChange,
    flagMobileUnverified,
    flagMobileVerified,
    startMobileValidationTimer,
  } = useForm();
  const { environment } = useEnvironment();
  // masked fields should not need form validation
  const maskedFields = formData?.vehicle.maskedFields ?? [];

  const changeMaskedField = (fieldName, value) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData.vehicle[fieldName] = value;
      // remove from maskedFields array
      if (maskedFields.includes(fieldName)) {
        updatedFormData.vehicle.maskedFields = updatedFormData.vehicle.maskedFields
          .filter(maskedField => maskedField !== fieldName);
      }
      return updatedFormData;
    });
  };

  const maskedFieldsMessage = maskedFields.length > 0 ? t('driverDetails.isDataStillCorrect') : null;
  const defaultCountryCode = environment?.communication?.defaultPhoneCode ?? '+32';
  const mustDoMobileValidation = environment?.communication?.verifyMobile;

  const validateEmail = () => {
    const regex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(formData?.vehicle.driver_email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // if email is unmasked, do validation by basic regex
    if (!maskedFields.includes('driver_email') && !validateEmail()) {
      toast.error(t('driverDetails.emailError'));
      return;
    }
    if (mustDoMobileValidation) {
      // there is a pending validation timer
      if (formData.mobileValidationTimer) {
        nextPage();
        return;
      }
      // send validation request
      setLoading(true);
      const { messageSent, alreadyValidated } = await validatePhoneNumber(formData, language, environment);
      // mobile number already validated --> skip validation page
      if (alreadyValidated) {
        flagMobileVerified();
        nextPage();
      } else if (messageSent) {
        startMobileValidationTimer();
        nextPage();
      } else {
        toast.error('Unexpected case from validation handler');
      }
    } else {
      nextPage();
    }
    setLoading(false);
  };

  return (
    <>
      <PageHeader title={t('driverDetails.title')} subtitle={t('driverDetails.subtitle')} />
      {maskedFieldsMessage && (
        <div className="text-center">
          <b>{maskedFieldsMessage}</b>
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <Row className="text-center">
          <Col>
            <Form.Label>{t('driverName')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="vehicle.driver_name"
              className="text-center"
              type="text"
              placeholder={t('driverNamePlaceholder')}
              value={formData?.vehicle.driver_name}
              onChange={(event) => changeMaskedField('driver_name', event.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-2 text-center">
          <Col>
            <Form.Label>{t('driverMobile')}</Form.Label>
            <Row className="g-1">
              <Col xs={4} md={3}>
                <CountryCodeSelect
                  value={formData?.mobile_country_code ?? defaultCountryCode}
                  name="mobile_country_code"
                  onChange={handleInputChange} />
              </Col>
              <Col>
                <Form.Control
                  required
                  autoComplete="off"
                  name="vehicle.driver_mob_no"
                  className="text-center"
                  type="text"
                  placeholder={t('driverMobilePlaceholder')}
                  value={formData?.vehicle.driver_mob_no}
                  onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                    changeMaskedField('driver_mob_no', numericValue);
                    flagMobileUnverified(); // mobile numbers needs to be verified after every change
                  }}
                />
              </Col>
            </Row>

          </Col>
        </Row>
        <Row className="mt-2 text-center">
          <Col>
            <Form.Label>{t('driverEmail')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="vehicle.driver_email"
              className="text-center"
              type={maskedFields.includes('driver_email') ? 'text' : 'email'}
              placeholder={t('driverEmailPlaceholder')}
              value={formData?.vehicle.driver_email}
              onChange={(event) => changeMaskedField('driver_email', event.target.value)}
            />
          </Col>
        </Row>
        {maskedFieldsMessage && <p className="text-center text-muted mt-3"><small>* {t('maskedFieldsNote')}</small></p>}
        <PageControls loading={loading} />
      </Form>
    </>
  );
}

export default DriverDetails;