import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { PreviousButton, NextButton } from '../../Controls';
import { usePage } from '../../../context/ContextProvider';

const PageControls = ({
  className,
  nextIcon,
  prevIcon,
  nextHandler,
  nextText,
  loading,
  drawPreviousButton = true,
}) => {
  const { prevPage } = usePage();
  return (
    <Row className={`${className ?? 'mt-3 g-1'}`}>
      {drawPreviousButton &&
        <Col xs={5} md={4}>
          <PreviousButton loading={loading} onClick={prevPage} icon={<FontAwesomeIcon icon={prevIcon ?? faChevronLeft} />}  />
        </Col>
      }
      <Col xs={drawPreviousButton ? 7 : 12} md={ drawPreviousButton ? 8 : 12}>
        <NextButton text={nextText} loading={loading} onClick={nextHandler} icon={<FontAwesomeIcon icon={nextIcon ?? faCheck} />}  />
      </Col>
    </Row>
  );
};

PageControls.propTypes = {
  className: PropTypes.string,
  nextIcon: PropTypes.object,
  prevIcon: PropTypes.object,
  nextHandler: PropTypes.func,
  loading: PropTypes.bool,
  drawPreviousButton: PropTypes.bool,
  nextText: PropTypes.string,
};

export default PageControls;