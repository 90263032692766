import React, { useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import i18n from '../../i18n';
import { registerLocale, setDefaultLocale } from  'react-datepicker';
import { nl, fr, enGB, de } from 'date-fns/locale';
import { useEnvironment, usePage, useForm } from '../../context/ContextProvider';
import { setSessionLanguage } from '../../handlers/environment';
import {
  LicenseplateLookup,
  DriverDetails,
  MobileVerification,
  VehicleDetails,
  TyreDetails,
  CustomerDetails,
  ServicePicker,
  DatePicker,
  Summary,
  Error,
  SuccessMessage,
} from '../FormPages';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Main.scss';

// update locale to allow capitalized month names
const customNl = {
  ...nl,
  localize: {
    ...nl.localize,
    month: (n) => {
      const monthNames = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
      return monthNames[n];
    }
  }
};

const customFr = {
  ...fr,
  localize: {
    ...fr.localize,
    month: (n) => {
      const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
      return monthNames[n];
    }
  }
};
registerLocale('nl', customNl);
registerLocale('fr', customFr);
registerLocale('en', enGB);
registerLocale('de', de);

function darkenColor(color, amount = 20) { // Default amount to darken by
  const colorInt = parseInt(color.slice(1), 16);
  let r = (colorInt >> 16) - amount;
  let g = ((colorInt >> 8) & 0x00FF) - amount;
  let b = (colorInt & 0x0000FF) - amount;

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  return `#${r.padStart(2, '0')}${g.padStart(2, '0')}${b.padStart(2, '0')}`;
}

function lightenColor(color, amount = 20) { // Default amount to lighten by
  const colorInt = parseInt(color.slice(1), 16);
  const r = (colorInt >> 16) + amount;
  const g = ((colorInt >> 8) & 0x00FF) + amount;
  const b = (colorInt & 0x0000FF) + amount;
  // return as rgba with alpha of 0.25
  return `rgba(${r}, ${g}, ${b}, 0.25)`;
}

function isColorDark(color) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  // Using the luminance formula to determine brightness
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance < 150; // Threshold of brightness, can be adjusted
}

// based on multiple parameters, certain pages will not be included to make nextPage() and prevPage() work as expected
const getPageComponents = (verifyMobile, mobileVerified, isContactService, getTyreDetails) => (
  [
    { component: LicenseplateLookup, key: 'LicenseplateLookup' },
    { component: DriverDetails, key: 'DriverDetails' },
    ...verifyMobile && !mobileVerified ? [{ component: MobileVerification, key: 'MobileVerification' }] : [],
    { component: VehicleDetails, key: 'VehicleDetails' },
    { component: CustomerDetails, key: 'CustomerDetails' },
    { component: ServicePicker, key: 'ServicePicker' },
    ...getTyreDetails !== 'doNotAsk' ? [{
      component: TyreDetails,
      key: 'TyreDetails',
      props: { getQuantities: getTyreDetails === 'askWithQuantity' }
    }] : [],
    ...!isContactService ? [{ component: DatePicker, key: 'DatePicker' }] : [],
    { component: Summary, key: 'Summary' },
    { component: SuccessMessage, key: 'SuccessMessage' },
  ]
);

function ContentWrapper() {
  const { environment, resetEnvironment } = useEnvironment();
  const { formData } = useForm();
  if (environment === undefined) {
    return <div
      style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      Agenda not active.
    </div>;
  }
  const styling = environment?.styling;
  const enabledLanguages = styling?.enabledLanguages ?? ['en', 'fr', 'nl'];
  const backgroundColor = styling?.backgroundColor;
  const primaryColor = styling?.primaryColor;
  const secondaryColor = styling?.secondaryColor;
  const logoUrl = styling?.logoUrl;

  const { currentPage, pageError, goToPage } = usePage();
  const { resetForm } = useForm();

  const setLanguage = (language) => {
    i18n.changeLanguage(language);
    setSessionLanguage(language);
    setDefaultLocale(language);
  };

  // set defaultlanguage on first load
  useEffect(() => {
    setLanguage(styling?.defaultLanguage ?? 'en');
  }, [styling]);

  // listen for window wevents "sessionExpired"
  // on received event, reset form and go to first page
  useEffect(() => {
    const handleSessionExpired = () => {
      console.log('Session expired, resetting');
      goToPage(0); // go to first page
      resetForm();// reset form
      resetEnvironment(); // fetch new environment to reset session agendaconfig
    };
    window.addEventListener('sessionExpired', handleSessionExpired);
    return () => {
      window.removeEventListener('sessionExpired', handleSessionExpired);
    };
  }, [resetForm, resetForm]);

  const includeMobileVerification = environment?.communication?.verifyMobile;
  const mobileVerified = formData.mobile_verified;
  const isContact = formData.selectedService?.isContact;
  const getTyreDetails = formData.selectedService?.getTyreDetails;
  const pagesConfig = getPageComponents(
    includeMobileVerification,
    mobileVerified,
    isContact,
    getTyreDetails,
  );

  const pages = pagesConfig.map(({ component: PageComponent, key, props }) => (
    <PageComponent key={key} {...props} />
  ));

  useEffect(() => {
    if (primaryColor && secondaryColor) {
      const textPrimary = isColorDark(primaryColor) ? '#ffffff' : '#000000';
      const textSecondary = isColorDark(secondaryColor) ? '#ffffff' : '#000000';

      document.documentElement.style.setProperty('--primary-color', primaryColor ?? '#007bff');
      document.documentElement.style.setProperty('--secondary-color', secondaryColor ?? '#6c757d');
      // on hover effects should be slightly darker
      document.documentElement.style.setProperty('--primary-color-darker', darkenColor(primaryColor));
      document.documentElement.style.setProperty('--secondary-color-darker', darkenColor(secondaryColor));
      // focused input fields etc should be slightly lighter
      document.documentElement.style.setProperty('--primary-color-lighter', lightenColor(primaryColor));
      document.documentElement.style.setProperty('--secondary-color-lighter', lightenColor(secondaryColor));
      // if colors are dark, set text color to white
      document.documentElement.style.setProperty('--text-primary', textPrimary);
      document.documentElement.style.setProperty('--text-secondary', textSecondary);
    }
  }, [primaryColor, secondaryColor]);

  return (
    <div className="main-container" style={{ backgroundColor }}>
      { logoUrl && <img className="logo mb-3" src={`${process.env.PUBLIC_URL}/logos/${logoUrl}`} alt="Logo" /> }
      <Container className="content-container rounded bg-white text-dark p-5">
        {pageError ?
          <Error message={pageError} />
          : pages[currentPage]}
      </Container>
      <div className="language-picker">
        {enabledLanguages.map((language, i) => (
          <React.Fragment key={language}>
            <Button className="btn btn-sm" variant="link" onClick={() => setLanguage(language)}>{language.toUpperCase()}</Button>
            {i < enabledLanguages.length - 1 && ' | '}
          </React.Fragment>
        ))}
      </div>
      <Toaster
        position="bottom-center"
      />
    </div>
  );
}

export default ContentWrapper;