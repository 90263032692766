import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageControls } from './shared';
import { useForm, useEnvironment } from '../../context/ContextProvider';
import { validatePhoneNumber, verifyValidationCode } from '../../handlers/mobileValidation';

function MobileVerification() {
  const { environment } = useEnvironment();
  const { formData, flagMobileVerified, startMobileValidationTimer } = useForm();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { language } = i18n ?? {};

  const resendValidationCode = async () => {
    setLoading(true);
    const success = await validatePhoneNumber(formData, language, environment);
    if (success) {
      startMobileValidationTimer();
    }
    setLoading(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const phoneVerified = await verifyValidationCode(code);
    if (phoneVerified) {
      // nextPage() is not necessary here, flagging mobile as verified
      // will omit the verification page and automatically go to the VehicleDetails page
      flagMobileVerified();
    }
    setLoading(false);
  };

  return (
    <>
      <PageHeader title={t('mobileValidation.title')} subtitle={t('mobileValidation.subtitle')} />
      <Form onSubmit={onSubmit}>
        <Row className="text-center mt-3">
          <Col>
            <Form.Label>{t('mobileValidation.code')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="mobile_validation_code"
              className="text-center"
              size="lg"
              type="text"
              value={code}
              onChange={(event) => setCode(event.target.value)}
              placeholder={t('mobileValidation.codePlaceholder')}
            />
            <small>{t('mobileValidation.help')}</small>
          </Col>
        </Row>
        <Row className="text-center mt-2">
          <Col>
            <Button
              disabled={loading || formData.mobileValidationTimer > 0}
              variant="link"
              onClick={resendValidationCode}
            >
              {formData.mobileValidationTimer > 0
                ? t('mobileValidation.resendCodeIn', { secondsRemaining: formData.mobileValidationTimer })
                : t('mobileValidation.resendCode')
              }
            </Button>
          </Col>
        </Row>
        <PageControls loading={loading} />
      </Form>
    </>
  );
}

export default MobileVerification;